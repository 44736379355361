import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"
import CareersForm from "../components/CareersForm"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import "../styles/commonStyleSheet.scss"
import { SEO } from "../components/SEO"

const Careers = props => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  // eslint-disable-next-line no-unused-vars
  const [jobs, setJobs] = useState([])
  return (
    <Layout location={props.location} pageContext={props.pageContext}>
      <SEO/>
      <Grid
        container
        spacing={3}
        style={{
          paddingLeft: 60,
          paddingTop: 100,
          paddingBottom: 20,
          paddingRight: 350,
        }}
        className={locale === "zh" && "chinese-version"}
      >
        <Grid item lg={4} md={6} xs={10}>
          <Typography
            variant="h4"
            component="h4"
            style={{ fontFamily: "clearface", fontSize: 52 }}
          >
            Jobs at Zhutravel
          </Typography>
          <Typography
            style={{ fontSize: 16, marginTop: 16, fontFamily: "MabryProLight" }}
          >
            We believe creative thinking is the driving force for the humankind.
            It’s also the foundation of our very own company culture. Something
            more will be here.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{ paddingLeft: 23, paddingBottom: 60, paddingRight: 60 }}
      >
        {jobs.length > 0 &&
          jobs.map(job => (
            <Grid item lg={3} md={6} xs={10}>
              <ul>
                <Typography
                  style={{
                    fontSize: 20,
                    fontFamily: "MabryProBold",
                    marginTop: 105,
                  }}
                >
                  {job.title}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "MabryProLight",
                    fontSize: 16,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: job.requirement }}
                  ></div>
                </Typography>

                <Typography
                  style={{
                    marginTop: 5,
                    fontSize: 12,
                    marginBottom: 10,
                    fontFamily: "MabryProBold",
                  }}
                >
                  Requirments
                </Typography>

                {job.roles.map(role => {
                  return (
                    <>
                      <li style={{ fontFamily: "MabryProLight", fontSize: 16 }}>
                        Role: {role.rolename}
                      </li>
                      <li style={{ fontFamily: "MabryProLight" }}>
                        Positions: {role.noposition}
                      </li>
                    </>
                  )
                })}

                <Button
                  variant="outlined"
                  style={{
                    marginTop: 40,

                    backgroundColor: "white",
                  }}
                  href="#apply"
                >
                  <Typography
                    style={{
                      fontFamily: "MabryProRegular",
                      textTransform: "initial",
                      fontSize: 16,
                    }}
                  >
                    Apply now
                  </Typography>
                </Button>
              </ul>
            </Grid>
          ))}
      </Grid>
      <Divider style={{ marginTop: 60 }} />
      <CareersForm jobs={jobs} />
      <Grid contsainer justify="center">
        <Grid xs={11} sm={10} item>
          <Footer />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Careers
