import React from "react"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
// eslint-disable-next-line no-unused-vars
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import "react-multi-carousel/lib/styles.css"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { Snackbar, IconButton, CircularProgress } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { apiUrl } from "../../config/api"
import Axios from "axios"

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 376,
    width: 508,
  },
  image: {
    height: 500,
    width: 600,
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    "&$focusedLabel": {
      color: "black",
    },
    "&$erroredLabel": {
      color: "black",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "black",
    },
    "&:after": {
      borderBottom: `1px solid black`,
    },
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
  
}))

const CareerForm = props => {
  const classes = useStyles()
  const [role, setrole] = React.useState("")

  const handleChange = e => {
    setrole(e.currentTarget.innerText)
    setJobId(e.target.value)
  }

  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [jobid, setJobId] = React.useState("")
  const [cv, setCV] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState("")
  const [success, setSuccess] = React.useState("")

  const handleSubmit = () => {
    var formData = new FormData()
    setLoading(true)
    formData.append("CV", cv)

    Axios.post(
      `${apiUrl}/apply`,
      { data: { name, email, jobid, role, cv: formData, message } },
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
      .then(res => {
        setLoading(false)

        if (res.data.iserror) {
          setError(res.data.message)
        } else {
          setSuccess(res.data.message)
        }
      })
      .catch(error => {
        setLoading(false)
        setError(error.message)
      })
  }
  return (
    <form
      style={{
        paddingLeft: 60,

        marginTop: 60,
        marginBottom: 60,
      }}
      id="apply"
      onSubmit={handleSubmit}
    >
      <Snackbar
        open={success.length}
        autoHideDuration={6000}
        onClose={() => setSuccess("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSuccess("")} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error.length}
        autoHideDuration={6000}
        onClose={() => setError("")}
      >
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Grid container spacing={4}>
        <Grid item xs={10} md={6} lg={6}>
          <img
            alt="img"
            style={{
              width: "100%",
              height: 620,
              marginLeft: 10,
              marginTop: 110,
            }}
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRD_gWPsY7-Rgtd353WunNH4TVaP0Pvub_uj-pWEqfTrMLdsx4ugg&s"
          />
        </Grid>
        <Grid item xs={10} md={6} lg={6} className={classes.column}>
          <Grid container justify="center">
            <Grid item xs={12} lg={8}>
              <Typography
                variant="h5"
                component="h5"
                style={{
                  fontFamily: "clearface",
                  fontSize: 40,
                  marginTop: 110,
                }}
              >
                Application Form
              </Typography>
              <Typography
                style={{
                  fontFamily: "MabryProLight",
                  fontSize: 16,
                  marginTop: -3,
                }}
              >
                We’re here to help so drop us a line
              </Typography>

              <TextField
                id="standard-full-width"
                label={
                  <div
                    style={{
                      color: "#010204",
                      fontSize: 14,
                      fontFamily: "MabryProLight",
                    }}
                  >
                    Full name
                  </div>
                }
                style={{ margin: 8, width: 400, marginTop: 40 }}
                placeholder="Type your name"
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.underline,
                    error: classes.error,
                  },
                  style: { fontFamily: "MabryProLight" },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                  shrink: true,
                }}
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}
              />
              <TextField
                id="standard-full-width"
                label={
                  <div
                    style={{
                      color: "#010204",
                      fontSize: 14,
                      fontFamily: "MabryProLight",
                    }}
                  >
                    Email address
                  </div>
                }
                style={{ margin: 8, width: 400, marginTop: 32 }}
                placeholder="Type email address"
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.underline,
                    error: classes.error,
                  },
                  style: { fontFamily: "MabryProLight" },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                  shrink: true,
                  fontFamily: "MabryProLight",
                }}
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
              <TextField
                id="standard-select-currency"
                select
                style={{ marginTop: 32 }}
                label={
                  <div
                    style={{
                      color: "#010204",
                      fontSize: 14,
                      fontFamily: "MabryProLight",
                    }}
                  >
                    Role
                  </div>
                }
                className={classes.textField}
                value={jobid}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: classes.underline,
                    error: classes.error,
                  },
                  style: { fontFamily: "MabryProLight" },
                  placeholder: "Select Role",
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                  shrink: true,
                  fontFamily: "MabryProLight",
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {props.jobs.map(option => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="text-button-file"
                  type="file"
                  onChange={e => {
                    setCV(e.target.files[0])
                  }}
                />
                <label htmlFor="text-button-file">
                  <IconButton component="span">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <VerticalAlignTopIcon />
                      {/* <Typography
                        style={{
                          fontFamily: "MabryProRegular",
                          fontSize: 14,
                          marginTop: 10,
                        }}
                      >
                        Upload files here
                      </Typography> */}
                    </div>
                  </IconButton>
                </label>
              </div>
              <TextField
                id="standard-full-width"
                label={
                  <div
                    style={{
                      color: "#010204",
                      fontSize: 14,
                      fontFamily: "MabryProLight",
                    }}
                  >
                    Message
                  </div>
                }
                style={{ margin: 8, width: 400 }}
                placeholder="Type your message"
                margin="normal"
                InputProps={{
                  classes: {
                    root: classes.underline,
                    error: classes.error,
                  },
                  style: { fontFamily: "MabryProLight" },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                  shrink: true,
                  fontFamily: "MabryProLight",
                }}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
              <Button
                variant="contained"
                style={{
                  width: 200,
                  marginTop: 30,
                  color: "white",
                  backgroundColor: loading ? "grey" : "black",
                  textTransform: "capitalize",
                  fontFamily: "MabryProLight",
                }}
                endIcon={
                  loading ? (
                    <CircularProgress size={16} />
                  ) : (
                    <div className={classes.arrow}>
                      <ArrowForward />
                    </div>
                  )
                }
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
              >
                Send Application
              </Button>

              <Card className={classes.card} style={{ marginTop: 30 }}>
                <CardContent>
                  <Typography
                    style={{ fontFamily: "MabryProBold", fontSize: 20 }}
                  >
                    Hate Application forms?
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MabryProLight",
                      fontSize: 16,
                      marginTop: -3,
                    }}
                  >
                    Fear not! You can apply directly to our email!
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        alt=""
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 5,
                          marginTop: 32,
                        }}
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVvE-8Q3hwt7inftvZY7bfYtwpgiP-DoyZip5ipDu34ED-635x&s"
                      />
                      <Typography
                        style={{
                          marginTop: 32,
                          fontFamily: "MabryProRegular",
                          textTransform: "lowercase",
                        }}
                      >
                        hello@Zhutravel.com
                      </Typography>
                    </div>
                    <div className={classes.arrow}>
                      <ArrowForward style={{ width: "80%", marginTop: 10 }} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider light style={{ marginTop: 120 }} />
    </form>
  )
}

export default CareerForm
